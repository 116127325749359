.header-root {
    margin-right:  3%;
    margin-left: 3%;
    background-color: #2c2c2e;
} 

.logo {
    float: left;
    margin-top: 10px;
    margin-right: 20px;
}

.ant-menu{
    background: #2c2c2e;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #2c2c2e !important;
    border-bottom: 0px solid #2c2c2e !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after {
    border-bottom: 0px solid #2c2c2e !important;
    border-bottom-width: 0px !important;
    border-bottom-style: solid !important;
    border-bottom-color: #2c2c2e !important;
}

.ant-menu-horizontal {
    line-height: 46px;
    border: 0;
    border-bottom: 0px solid #2c2c2e !important;
    box-shadow: none;
}

.inner{
    height: 50px;
    background: #2c2c2e;
    position:fixed;
    width:100%;
    left:0;
    top:0;
    right: 0;
    z-index: 1000;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin: 0 10px;
    margin-top: -1px;
    margin-bottom: 0;
    padding: 0 20px;
    padding-right: 0;
    padding-left: 0;
}

.language-Btn {
    margin-right: 0px;
    margin-top: 0px;
}


