.root-bg {
    background-color: #2c2c2e;
    height: 185px;
}

.inner-footer2{
    background: #2c2c2e;
    margin-right:  5%;
    margin-left: 5%;
}

.header-root2{
    margin-right:  10%;
    margin-left: 10%;
    background-color: #2c2c2e;
} 

.logo222 {
    margin-top: 25px;
    float: left;
    /* margin-left: -33px; */
}

.ant-menu{
    background: #2c2c2e;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #2c2c2e !important;
    border-bottom: 0px solid #2c2c2e !important;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected:after, .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover:after {
    border-bottom: 0px solid #2c2c2e !important;
    border-bottom-width: 0px !important;
    border-bottom-style: solid !important;
    border-bottom-color: #2c2c2e !important;
}

.ant-menu-horizontal {
    line-height: 46px;
    border: 0;
    border-bottom: 0px solid #2c2c2e !important;
    box-shadow: none;
}



.footer-copyright {
    float: center;
    margin-top: 10px;
    color: #8e8e93;
    font-size: 13px;
}

.footer-copyright2 {
    float: center;
    margin-top: 10px;
    color: #8e8e93;
    font-size: 13px;
}

.gray-lane {
    margin-top: 0px;
    height: 1px;
    background-color: #3a3a3c;
    width: 100%
}

.contact-title {
    margin-top: 20px;
    margin-right: 114px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #d1d1d6;
}

.footer-contact {
    text-align: right;
}

.footer-contact p {
    font-size: 14px;
    color: #d1d1d6;
    
}

.p2 {
    font-size: 14px;
    color: #000;
}

.col1 {
    /* background-color: grey; */
}

.col2 {
    /* background-color: red; */
}

.col3 {
    /* background-color: green; */
}

.col4 {
    /* background-color: black; */
}