.root {
    text-align: left;
    margin-top: 70px;
    margin-right:  10%;
    margin-left: 10%;
}

.point {
    margin-left: 0%;
    width: 100px;
}

.point2 {
    margin-left: 0%;
}

