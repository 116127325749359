.table {
    margin-right:  5%;
    margin-left: 5%;
}

.point {
    float: left;
}

.keycapTitle {
    text-align: center;
    margin-top: 70px;
    margin-bottom: 20px;
}

